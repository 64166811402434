<div *ngIf="notificationBarService.notifications.length > 0" class="row notifications-container">
	<div class="col-lg-12 col-md-12 col-sm-12 col-12 nb">
		<div *ngFor="let notification of notificationBarService.notifications; let i = index;" class="notification {{notification.typeValue}}"
			(mouseover)="hideOnHover(notification)" [@flyDown]>
			<div *ngIf="notification.isHtml" class="message">
				<span id="{{notification.typeValue}}"></span>
				<span class="not-title">{{notification.title}}</span>
				<span [innerHtml]="notification.message"></span>
                <a *ngIf="notification.routerLink && notification.routerLink.length > 0" style="margin-left: 5px;" (click)="clickRouteNotificacion(notification)">Acceder</a>
				<span class="close-click" *ngIf="notification.allowClose" (click)="hideNotification(notification)">×</span>
			</div>
			<div *ngIf="!notification.isHtml" class="message">
				{{notification.title}}
				{{notification.message}}
				<span class="close-click" *ngIf="notification.allowClose" (click)="hideNotification(notification)">×</span>
			</div>
		</div>
	</div>
</div>