export const navigationTopAdmin = [
            
    {
        id: 'persona-list',
        title: 'Personas',
        type: 'collapsable',
        role     : ['a', 'o', 'p'],
        children : [
            {
                id       : 'usuarios-list',
                title    : 'Usuarios',
                type     : 'item',
                url      : '/general/persona-hija-list/usuarios',
                role     : ['a']
            },
            {
                id       : 'clientes-list',
                title    : 'Clientes',
                type     : 'item',
                url      : '/general/persona-hija-list/clientes',
                role     : ['a']
            },
            {
                id       : 'proveedores-list',
                title    : 'Proveedores',
                type     : 'item',
                url      : '/general/persona-hija-list/proveedores',
                role     : ['a']
            },
            {
                id       : 'prestadores-servicios-list',
                title    : 'Prestador de Servicios',
                type     : 'item',
                url      : '/general/persona-hija-list/prestadores_servicios',
                role     : ['a']
            },
            {
                id       : 'organizaciones-list',
                title    : 'Organizaciones',
                type     : 'item',
                url      : '/general/persona-hija-list/organizaciones',
                role     : ['a']
            },
            {
                id       : 'especialistas-list',
                title    : 'Especialistas',
                type     : 'item',
                url      : '/general/persona-hija-list/especialistas',
                role     : ['a']
            },
            {
                id       : 'supervisores-list',
                title    : 'Supervisores',
                type     : 'item',
                url      : '/general/persona-hija-list/supervisores',
                role     : ['a']
            },
            {
                id       : 'distribuidores-list',
                title    : 'Distribuidores',
                type     : 'item',
                url      : '/general/persona-hija-list/distribuidores',
                role     : ['a']
            },
            {
                id       : 'dependientes-organizacion-list',
                title    : 'Dependientes de la Organizacion',
                type     : 'item',
                url      : '/general/persona-by-organizacion-list',
                queryParams : {o:0},
                role     : ['a', 'o']
            }
        ]
    },
    {
        id       : 'generales',
        title    : 'Generales',
        type     : 'collapsable',
        role     : ['a', 'o', 'p'],
        children : [
            {
                id       : 'ciudades-list',
                title    : 'Ciudades',
                type     : 'item',
                url      : '/general/tipo-list/ciudad',
                role     : ['a']
            },
            {
                id       : 'idiomas-list',
                title    : 'Idiomas',
                type     : 'item',
                url      : '/general/tipo-list/idioma',
                role     : ['a']
            },
            {
                id       : 'paises-list',
                title    : 'Paises',
                type     : 'item',
                url      : '/general/tipo-list/pais',
                role     : ['a']
            },
            {
                id       : 'departamentos-list',
                title    : 'Departamentos',
                type     : 'item',
                url      : '/general/tipo-list/departamento',
                role     : ['a']
            },
            {
                id       : 'perfiles-list',
                title    : 'Perfiles',
                type     : 'item',
                url      : '/general/tipo-list/perfil',
                role     : ['a']
            },
            {
                id       : 'zonas-geograficas-list',
                title    : 'Zonas geograficas',
                type     : 'item',
                url      : '/general/tipo-list/zona_geografica',
                role     : ['a']
            },
            {
                id       : 'perfiles-preguntas-list',
                title    : 'Preguntas para el Usuario',
                type     : 'item',
                url      : '/general/tipo-list/perfil_preguntas',
                role     : ['a']
            },
            {
                id       : 'opciones-perfiles-list',
                title    : 'Preguntas del Perfil',
                type     : 'item',
                url      : '/general/perfil-opcion-list/perfil',
                role     : ['a']
            },
            {
                id       : 'monedas-list',
                title    : 'Monedas',
                type     : 'item',
                url      : '/general/moneda-list',
                role     : ['a']
            },
            {
                id       : 'tipos-servicios-list',
                title    : 'Tipos de Servicios',
                type     : 'item',
                url      : '/general/tipo-servicio-list/tipo_servicio',
                role     : ['a']
            },
            {
                id       : 'definicion-dato-list',
                title    : 'Definición de Datos',
                type     : 'item',
                url      : '/general/tipo-definicion-dato-list/definicion-dato',
                role     : ['a']
            },
            {
                id       : 'definicion-dato-list',
                title    : 'Datos Personalizados',
                type     : 'item',
                url      : '/general/dato-personalizado-list/',
                role     : ['a']
            },
            {
                id       : 'turnos-list',
                title    : 'Turnos',
                type     : 'item',
                url      : '/general/tipo-servicio-list/turno',
                role     : ['a']
            },
            {
                id       : 'tipos-especialistas',
                title    : 'Tipos de Especialistas',
                type     : 'item',
                url      : '/general/tipo-list/tipo-especialista',
                role     : ['a']
            },
            {
                id       : 'especialidades-list',
                title    : 'Especialidades',
                type     : 'item',
                url      : '/general/tipo-especialidad-list/especialidad',
                role     : ['a']
            },
            {
                id       : 'campos-personalizados-list',
                title    : 'Campos Personalizados',
                type     : 'item',
                url      : '/general/tipo-list/campo_personalizado',
                role     : ['a']
            },
            {
                id       : 'cotizaciones-list',
                title    : 'Cotizaciones',
                type     : 'item',
                url      : '/general/cotizacion-list',
                role     : ['a']
            },
        ]
    }
];
