import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
      protected router: Router,
      protected keycloakService: KeycloakService
    ) { 
        super(router, keycloakService);
    }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean|UrlTree> {

    return new Promise(async (resolve, reject) => {

        if ( ! this.authenticated ) {
            //console.log("->", window.location.origin, state.url)
            this.keycloakService.login({
                redirectUri: window.location.origin + state.url,
                locale: 'es_ES'
            });
            resolve(false);
        }

        const requiredRoles = route.data.roles;
        let granted: boolean = false;
        
        if (!requiredRoles || requiredRoles.length === 0) {
            granted = true;
        } else {
            for (let i = 0; i < requiredRoles.length; i++) {
                const requiredRole = requiredRoles[i];
                if (this.roles.indexOf(requiredRole) > -1) {
                    granted = true;

                    //this.keycloakService.get
                    break;
                }
            }
        }

        resolve(granted);
    });
    
  }

}
