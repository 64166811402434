import { MatDividerModule } from '@angular/material/divider';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import { DirectivesModule } from 'app/directives/directives.module';
import { DeRecibidoConfigComponent } from './de-recibido-config.component';

//import { DeRecibidoConfigEditComponent } from './de-recibido-config.component';

/*const routes: Routes = [
    { path: '', component: DeRecibidoEditComponent },
    { path: ':id', component: DeRecibidoEditComponent },
    { path: 't/:id', component: DeRecibidoEditComponent, 
    data: {
        layout : 'innerTab', tabLabel: ':id == "0" ? "Nuevo" : "Ver #:id"'
    } 
}
];*/

@NgModule({
  declarations: [DeRecibidoConfigComponent],
  imports: [
    //RouterModule.forChild(routes),
    DirectivesModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTabsModule,
    MatSnackBarModule,
    FuseSharedModule,
    MatDividerModule,
    MatTooltipModule
    
  ]
})
export class DeRecibidoConfigModule { }
