import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { DayAgoPipe } from './dayAgo.pipe';
import { TimeAgoPipe } from './timeAgo.pipe';
import { HourAgoPipe } from './hourAgo.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DayAgoPipe,
        TimeAgoPipe,
        HourAgoPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DayAgoPipe,
        TimeAgoPipe,
        HourAgoPipe
    ]
})
export class FusePipesModule
{
}
