import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthService {

    constructor(private keycloakService: KeycloakService) { }

    getLoggedUser() {
        try {
            let userDetalis = this.keycloakService.getKeycloakInstance().idTokenParsed;
            //console.log("userDetalis", userDetalis);
            //console.log("userRoles", this.keycloakService.getUserRoles());
            return userDetalis;
        } catch (error) {
            console.log(" getLoggedUser error", error);
            return undefined;
        }
    }

    getLoggedUserPrueba() {
        try {
            let userDetalis = this.keycloakService.getKeycloakInstance();
            //console.log("userDetalis", userDetalis);
            //console.log("userRoles", this.keycloakService.getUserRoles());
            return userDetalis;
        } catch (error) {
            console.log(" getLoggedUser error", error);
            return undefined;
        }
    }

    loadUserInfo() {
        try {
            return this.keycloakService.getKeycloakInstance().loadUserInfo()
            
        } catch (error) {
            console.log(" getLoggedUser error", error);
            return undefined;
        }
    }

    loadUserProfile() {
        try {
            return this.keycloakService.getKeycloakInstance().loadUserProfile()
            
        } catch (error) {
            console.log(" getLoggedUser error", error);
            return undefined;
        }
    }

    logout() {
        localStorage.removeItem("tenant");
        localStorage.removeItem("ud");
        this.keycloakService.logout(environment.urlPWA);
    }

    redirectToProfile() {
        this.keycloakService.getKeycloakInstance().accountManagement();
    }

    getRoles() {
        return this.keycloakService.getUserRoles();
    }
}
