import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFocusDirective } from './auto-focus.directive';
import { FocusFirstInvalidFieldDirective } from './focus-invalid-input.directive';
import { ToolTipRendererDirective } from './tool-tip-renderer.directive';
import { NgInitDirective } from './ng-init.directive';
import { DndDirective } from './dnd.directive';

@NgModule({
    declarations: [
      AutoFocusDirective, 
      FocusFirstInvalidFieldDirective,
      ToolTipRendererDirective,
      NgInitDirective,
      DndDirective
    ],
    imports: [
      CommonModule
    ],
    exports: [
      AutoFocusDirective, 
      FocusFirstInvalidFieldDirective, 
      ToolTipRendererDirective,
      NgInitDirective
    ]
  })
export class DirectivesModule { }
