import { Injectable, EventEmitter } from "@angular/core";

import { Notification } from "./notification-bar.type";

import axios from 'axios';
import { environment } from 'environments/environment';
import { NotificationType, MessagesConfig } from "./notification-bar.type";

/**
 * A service to create notification, It can be used from any component or guard
 */
@Injectable({
    providedIn: 'root'
  })
export class NotificationBarService {
    onCreate = new EventEmitter<Notification>();

    notifications: Notification[] = [];

    defaults = {
        title: "",
        message: "",
        type: NotificationType.Info,
        autoHide: true,
        hideDelay: 900000,
        isHtml: true,
        allowClose: true,
        hideOnHover: false
    };

    constructor() {}

    create(notification: Notification) {
        this.onCreate.emit(notification);
    }

    refreshNotifications(tenant: string) {
        if (tenant) {   //Validacion por si aun no tienen TenantId
            axios.get(`${environment.url}${tenant}/notificacion/get-in-app` ).then((response: any) => { 
                    
                if(response.data.success){

                    this.notifications = [];

                    for(let i=0; i < response.data.result.length; i++) {
                        
                        let n = response.data.result[i];

                        let type: any = n.tipo == 'INFO' ? NotificationType.Info : n.tipo == 'SUCCESS' ? NotificationType.Success : n.tipo == 'ERROR' ? NotificationType.Error : n.tipo == 'WARNING' ? NotificationType.Warning : '';

                        this.create({
                            title : n.asunto,
                            //message: "Debe específicar una <b>clave de activación</b> para ésta copia de FacturaSend antes del 01/01/2025. Para más información acceda al menú \"Mi Cuenta\" -> \"Perfil\" -> \"Activación del Sistema\" <a routerLink=\"/pages/profile/personal\">/pages/profile/personal</a>",
                            message: n.mensaje,
                            type: type,
                            hideDelay : n.in_app_delay ? n.in_app_delay : this.defaults.hideDelay,
                            routerLink: n.route,
                            externalLink : n.link
                        });
                        
                    }
                

                } else {
                    /*this._matSnackBar.open(response.data.error, 'Error', {
                        verticalPosition: 'bottom',
                        duration: 3000
                    });*/
                }
            });        
        }
    }
}
