export const environment = {
    version : require('../../package.json').version,
    production: true,
    keycloakConfig : {
        clientId : 'KC_REPLACE_CLIENT_ID',
        realm : 'KC_REPLACE_REALM',
        url : 'KC_REPLACE_AUTH_URL'
    },
    url: 'FS_REPLACE_API_URL',
    urlWSS: 'FS_REPLACE_WSS_URL',
    urlPWA: 'FS_REPLACE_PWA_URL',
    publicAWSHost : 'FS_REPLACE_AWS_URL',
    useCaptcha : false
};

const getPortFromURL = function(url) {
    //console.log("getPortFromURL", url);
    const urlAnchor = document.createElement('a');
    //  Set href to any path
    urlAnchor.setAttribute('href', url);

    return urlAnchor.port;
}

if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(window.location.hostname)
|| window.location.hostname == 'localhost') {  

    environment.keycloakConfig.url = window.location.protocol + '//' + window.location.hostname + ':' + getPortFromURL(environment.keycloakConfig.url) + '/auth/'
    environment.url = window.location.protocol + '//' + window.location.hostname + ':' + getPortFromURL(environment.url) + '/api/';
    environment.urlWSS = window.location.protocol + '//' + window.location.hostname + ':' + getPortFromURL(environment.urlWSS) + '/';
    environment.urlPWA = window.location.protocol + '//' + window.location.host;
    console.log("Se hace el cambio por el ip del front");
}


console.log(environment);


