import { Component, EventEmitter, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector   : 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls  : ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    collapsed: boolean;
    fuseConfig: any;

    @Output()
    input: EventEmitter<any>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public dialog: MatDialog
    )
    {
        // Set the defaults
        this.input = new EventEmitter();
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (config) => {
                this.fuseConfig = config;
            }
        );
    }

    /**
     * Funcion que al ejecutarse abre el modal para agregar un elemento
     * como para editarlo
     * @param {*} [element] data del chip
     * @memberof ListFilterComponent
     */
    openDialogFiltro(templateHistorico, element?: any): void {

        const dialogRef = this.dialog.open(templateHistorico, {
        width: '500px',
        data: element,
        panelClass: 'my-dialog'
        });

        // Una vez que se haya cerrado el modal
        dialogRef.afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {

        // Si se se le dio Aceptar en el modal
        if (result) {
            if (element.add === false) {
            result.add = true;
            //this.listChips.push(result);
            } else {
            /*this.listChips.map(flt => {
                if (flt.name === element.name) {
                flt.value = element.value;
                }
            });*/
            }
            //this.emitirEvento();
        }
        });
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
    }

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        this.input.emit(event.target.value);
    }

}
