<div id="fuse-shortcuts-maestros" #shortcutsMaestros>

    <div fxLayout="row" >

        <div *ngIf="permission === 'a'" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="0 1 auto">

            <div fxLayout="row" fxLayoutAlign="start center">
                
                <a mat-icon-button [matMenuTriggerFor]="padreMenu" matTooltip="Maestros">
                    <mat-icon class="secondary-text">today</mat-icon>
                </a>

            </div>

        </div> 

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="0 1 auto">

            <div *ngIf="!hiddenSelectEmpresa" fxLayout="row" fxLayoutAlign="start center">
                <!--
                <a [routerLink]="['/chat']" mat-icon-button matTooltip="Chat">
                    <mat-icon class="secondary-text">message</mat-icon>
                    <div class="mt-4 unread-message-count " *ngIf="unReadChatsCount > 0">{{unReadChatsCount}}</div>
                </a>
                -->
                <mat-form-field appearance="outline">
                    <!--
                    <mat-label>Cambiar Empresa</mat-label>
                  -->
                    <mat-select [(value)]="empresaSelected.id" (selectionChange)="selectEmpresa($event.value)">
                        <ng-container *ngFor="let empresa of empresas; let index = index; let count = count;" >
                            <mat-divider *ngIf="empresa.id == -1"></mat-divider>
                            <mat-option [value]="empresa.id">
                                <span *ngIf="empresa.id > 0">{{empresa.nombre}}</span>
                                <span *ngIf="empresa.id == -1"><b>{{empresa.nombre}}</b></span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error>No puede dejar vacio</mat-error>
                </mat-form-field>
            </div>

        </div> 
        
        <mat-menu #padreMenu="matMenu" [overlapTrigger]="false" class="w-240">
            <div *ngFor="let padre of shortcutItems">
                <button mat-menu-item [matMenuTriggerFor]="hijoMenu">{{padre.title}}</button>
                <mat-menu #hijoMenu="matMenu">
                    <div *ngFor="let hijo of padre.children">
                        <button [routerLink]="hijo.url" fragment="" [queryParams]="hijo.queryParams" mat-menu-item>{{hijo.title}}</button>
                    </div>
                </mat-menu>
            </div>
        </mat-menu>

        <!-- <mat-menu #addMenu="matMenu" [overlapTrigger]="false" class="w-240">

            <div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

                <ng-container *ngIf="layout === 'vertical'">
            
                    <ng-container *ngFor="let item of shortcutItems">
                        <fuse-nav-vertical-group *ngIf="item.type == 'group'" [item]="item">
                        </fuse-nav-vertical-group>
                        <fuse-nav-vertical-collapsable *ngIf="item.type == 'collapsable'"[item]="item" (click)="$event.stopPropagation();">
                        </fuse-nav-vertical-collapsable>
                        <fuse-nav-vertical-item *ngIf="item.type == 'item'" [item]="item"></fuse-nav-vertical-item>
            
                    </ng-container>
            
                </ng-container>
            
            </div>

        </mat-menu> -->

    </div>

</div>
