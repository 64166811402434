import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { TabContentComponent } from 'app/layout/components/tab-content/tab-content.component';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
    declarations: [
        TabContentComponent
    ],
    imports     : [
        RouterModule,
        FuseSharedModule,
        MatTabsModule,
        PipesModule
    ],
    exports     : [
        TabContentComponent
    ]
})
export class TabContentModule
{
}
