
export enum NotificationType { Info, Success, Error, Warning }

export interface Notification {
    title: string;
    message: string;
    type?: NotificationType;
    routerLink?: string;
    externalLink?: string;
    typeValue?: string;
    autoHide?: boolean;
    hideDelay?: number;
    isHtml?: boolean;
    allowClose?: boolean;
    hideOnHover?: boolean;
}


export interface MessageConfig {
    [when: string]: string;
}

export interface MessagesConfig {
    messages?: MessageConfig;
}